import { ENVIRONMENT, LeagueConfig } from '@leagueplatform/core';
/**
 * @ts-ignore is used here due to @leagueplatform/legacy-locales being a
 * temporary measure that is served as a JS utility.
 */
// @ts-ignore
import { legacyStrings } from '@leagueplatform/legacy-locales';
import {
  CS_AUTH0_CLIENT_CONNECTION,
  LoadingSpinner,
} from '@highmark-web/highmark-common';
import { CLIENT_ID, TENANT_ID } from './common/constants';
import { ASSETS_CONFIG } from './common/asset-config';
import { EN_STRING_OVERRIDES } from './locales/sdk-string-overrides/en';
import { theme } from './theme/theme';

const isProduction = (import.meta.env.VITE_ENV as ENVIRONMENT) === 'production';

export const config: LeagueConfig = {
  core: {
    clientId: CLIENT_ID,
    tenantId: TENANT_ID,
    auth: {
      clientOptions: {
        domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
        client_id: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
        redirect_uri: window.location.origin,
        connection: CS_AUTH0_CLIENT_CONNECTION,
      },
      // Number of hours for which a session can be inactive before user is prompted to log out/continue
      // Note: Any changes to this value have to be synced with the idle_session_lifetime in the auth0 configuration.
      idleSessionLifetimeHours: 0.25,
    },
    api: {
      wsUrl: import.meta.env.VITE_API_URL as string,
      url: import.meta.env.VITE_REST_API_URL as string,
      legacyRestApi: import.meta.env.VITE_LEGACY_REST_API as string,
    },
    contentUrl: import.meta.env.VITE_CONTENT_SERVER_URL as string,
    appEnvironment: import.meta.env.VITE_ENV as ENVIRONMENT,
    customMethods: {
      handleLink: async (url: string) => {
        window.open(url, '_blank', 'noreferrer');
      },
    },
    i18n: {
      strings: {
        default: { ...legacyStrings.default, ...EN_STRING_OVERRIDES },
        en: { ...legacyStrings.en, ...EN_STRING_OVERRIDES },
      },
    },
    ui: {
      theme,
      components: {
        loading: { element: <LoadingSpinner />, text: false },
        pageHeader: {
          shape: 'line',
          backgroundColor: 'surface.background.secondary',
        },
      },
    },
  },
  assets: {
    overrides: ASSETS_CONFIG,
  },
  customerSupport: {
    enableActivityOverride: true,
    enableWalletInquiry: true,
    enableClaimsInquiry: true,
    enableMemberOverview: true,
    enableAssessmentsAndDashboards: true,
    enableJourneyInquiry: true,
    enableBiometricsV2: !isProduction,
    enablePageHeaderBanner: true,
    faqLink:
      'https://highmrk.saas.verinthms.com/GTConnect/UnifiedAcceptor/FrameworkDesktop.Main',
    claimsInquiry: {
      featureFlags: {
        shouldShowClaimDetails: true,
        shouldShowDownloadClaims: true,
        shouldShowFilterChips: true,
      },
      showClaimStatusDefinitions: true,
      showTotalCostOverviewAndAdditionalResources: false,
    },
  },
};
